import React, { useEffect, useState } from 'react';
import { setTitle } from '../utils/Title';
import { sendMessage } from '../utils/Fetch';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import FormGroup from '@material-ui/core/FormGroup';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import {Redirect} from 'react-router-dom';

export const ContactPage = () => {
  const maxLength = 500

  const [message, setMessage] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const handleMessageChange = (event: React.ChangeEvent<any>) => {
    if (event.target.value.length > maxLength) {
      event.target.value = event.target.value.substring(0, maxLength)
    }
    setMessage(event.target.value)
  }

  const handleSend = () => {
    const send = async () => {
      try {
        setIsSending(true)
        await sendMessage(message)
        window.alert("Sent!")
        setIsSent(true)
      } catch (error) {
        window.alert(error.message)
        setIsSending(false)
      }
    }

    send()
  }

  useEffect(() => {
    setTitle('Contact')
    return () => {}
  }, [])

  if (isSent) {
    return <Redirect to="/" />
  }

  return (
    <Grid container direction="column" justify="center" alignItems="center">
      <ContactContainer>
        <p>Got feedback? Bug? Want to say hi? Drop us a line!</p>
      </ContactContainer>
      <ContactContainer>
        <FormGroup>
          <TextField
            label="Message"
            multiline
            rows={4}
            margin="dense"
            variant="outlined"
            onChange={handleMessageChange}
            disabled={isSending}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSend}
            disabled={isSending || message.length === 0}
          >
            Send
          </Button>
        </FormGroup>
      </ContactContainer>
    </Grid>
  )
}

const ContactContainer = styled(Container)`
  max-width: 500px;
`;


import React, { useEffect, useState } from 'react';
import { setHomeTitle } from '../utils/Title';
import { pingPageView } from '../utils/Analytics';
import { fetchBookings } from '../utils/Fetch';
import styled from 'styled-components';
import { AddBookingForm } from '../components/AddBookingForm';
import { useGlobal } from 'reactn';
import Button from '@material-ui/core/Button';
import {BookingList} from '../components/BookingList';
import {BookingResponse} from '../models/Booking';
import CircularProgress from '@material-ui/core/CircularProgress';

export const HomePage = () => {
  const [isAddSuccessful] = useGlobal('isAddSuccessful')
  const [error, setError] = useState<Error | null>(null);
  const [response, setResponse] = useState<BookingResponse | null>(null);

  useEffect(() => {
    setHomeTitle()
    pingPageView()
    const loadBookings = async () => {
      try {
        const result = await fetchBookings()
        setResponse(result)
      } catch (err) {
        setError(err)
      }
    }

    loadBookings()
  }, [isAddSuccessful])

  const content = () => {
    if (error) {
      return (
        <div>
          Something went pretttty bad.<br /><br />
          Error: {error.message}
        </div>
      )
    } else if (response) {
      if (response.bookings.length === 0) {
        return <AddBookingForm isMaster={false} allUsers={[]} />
      }
      return (
        <div>
          <AddTripButton>
            <Button variant="contained" color="primary" href="/add">
              Add trip
            </Button>
          </AddTripButton>
          <BookingList bookingResponse={response} isMaster={false} />
        </div>
      )
    } else {
      return <div><CircularProgress /></div>
    }
  }

  return (
    <div>
      <p>
        Automatically check in to your Southwest flight!<br/>
        Add your trip and forget. We'll get you a good boarding position.<br/>
        You'll receive an email when you're checked in.<br/>
        Check-in times are local time and flight times are in the departing airport's time zone.<br/>
      </p>
      <hr/>
      <Content>{content()}</Content>
    </div>
  )
}

const Content = styled.div`
  margin-top: 20px;
`;

const AddTripButton = styled.div`
  margin-bottom: 10px;
`;

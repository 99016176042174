import React, {useState} from 'react';
import { Booking } from '../models/Booking';
import Button from '@material-ui/core/Button';
import { checkIn, refreshBooking } from '../utils/Fetch';
import styled from 'styled-components';
import Avatar from '@material-ui/core/Avatar';

export const AdminExtra = (props: {booking: Booking, isMaster: boolean}) => {
  const [isFetchingCheckin, setIsFetchingCheckin] = useState(false);
  const [isFetchingRefresh, setIsFetchingRefresh] = useState(false);

  const triggerCheckin = async () => {
    try {
      setIsFetchingCheckin(true)
      const result = await checkIn(props.booking.confirmationCode, props.isMaster)
      window.alert(result)
    } catch (err) {
      window.alert((err as Error).message)
    } finally {
      setIsFetchingCheckin(false)
    }
  }

  const triggerRefresh = async () => {
    try {
      setIsFetchingRefresh(true)
      const result = await refreshBooking(props.booking.confirmationCode, props.isMaster)
      window.alert(result)
    } catch (err) {
      window.alert((err as Error).message)
    } finally {
      setIsFetchingRefresh(false)
    }
  }


  var checkInButton = (
    <Button
      variant="outlined"
      color="primary"
      size="small"
      disabled={isFetchingCheckin}
      onClick={triggerCheckin}
    >
      Check In
    </Button>
  )

  var refreshBookingButton = (
    <Button
      variant="outlined"
      color="primary"
      size="small"
      disabled={isFetchingRefresh}
      onClick={triggerRefresh}
    >
      Refresh
    </Button>
  )

  var userDetail = <div />
  userDetail = (
    <div>
    {props.booking.users.map((user, _) => (
      <UserBlock>
        <UserInfo>
          <Avatar src={user.imageUrl} />
        </UserInfo>
        <UserInfo>
          <DetailText>
            {user.name}
            <br />
            {user.email}
          </DetailText>
        </UserInfo>
      </UserBlock>
    ))}
    </div>
  )

  return (
    <div>
      {userDetail}
      <div>
        <br/>
        {checkInButton}
        &nbsp;&nbsp;
        {refreshBookingButton}
      </div>
    </div>
  )
}

const UserBlock = styled.div`
  margin-top: 10px;
  display: flex;
`;

const UserInfo = styled.div`
  margin-right: 5px;
`;

const DetailText = styled.i`
  font-size: 12px;
`;

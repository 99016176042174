import React, { useEffect, useState } from 'react';
import { setTitle } from '../utils/Title';
import { fetchAllBookings } from '../utils/Fetch';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import {BookingList} from '../components/BookingList';
import {BookingResponse} from '../models/Booking';
import CircularProgress from '@material-ui/core/CircularProgress';
import { User } from '../models/User';

interface MasterProps {
  user: User
}

export const MasterPage = (props: MasterProps) => {
  const [response, setResponse] = useState<BookingResponse | null>(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [masterErr, setMasterErr] = useState("");

  const loadBookings = async () => {
    try {
      setIsRefreshing(true)
      const bookingsResult = await fetchAllBookings(true)
      setResponse(bookingsResult)
    } catch (err) {
      setMasterErr((err as Error).message)
    } finally {
      setIsRefreshing(false)
    }
  }

  const handleRefresh = () => {
    loadBookings()
  }

  useEffect(() => {
    setTitle('Master')
    if (props.user.isAdmin) {
      loadBookings()
    }
  }, [props.user])

  if (!props.user.isAdmin) {
    return (
      <div>
        You're no master!
      </div>
    )
  }

  if (masterErr !== "") {
    return (
      <div>
        Error getting master: {masterErr}
      </div>
    )
  }

  const content = () => {
    if (isRefreshing) {
      return <div><CircularProgress /></div>
    } else if (response) {
      if (response.bookings.length === 0) {
        return <div>No bookings</div>
      }
      return <BookingList bookingResponse={response} isMaster={true} />
    } else {
      return <div></div>
    }
  }

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        disabled={isRefreshing}
        onClick={handleRefresh}
      >
        Refresh
      </Button>
      &nbsp;&nbsp;
      <Button
        variant="contained"
        color="primary"
        href="/master/add"
      >
        Master Add Trip
      </Button>
      <Content>{content()}</Content>
    </div>
  )
}

const Content = styled.div`
  margin-top: 20px;
`;

import React from 'react';

export const FaqPage = () => {

  return (
    <div>
      <h3>FAQ</h3>
      <p>
        <b>Q: </b>How does this work?
        <br/>
        <b>A: </b>Add your booking when you reserve it, and as soon as it becomes eligible for check-in, Robofly will automatically check you in. No need to look at it again.
      </p>
      <p>
        <b>Q: </b>Do I need to add a booking for each passenger?
        <br/>
        <b>A: </b>No, just once.
      </p>
      <p>
        <b>Q: </b>I'm eligible for check-in, but Robofly didn't check me in.
        <br/>
        <b>A: </b>If it's an international flight, make sure all your documents are uploaded. Otherwise, sometimes there are integration failures. We do our best to minimize these over time, but please <a href="/contact">contact</a> us so we can improve.
      </p>
      <p>
        <b>Q: </b>Why did I not get an A boarding position?
        <br/>
        <b>A: </b>Our average boarding position is A36. However, sometimes on popular flights, priority members take up early boarding positions. Also some flights are second or third legs for other passengers, which allows them to check in earlier if their trip starts before yours. Check-in time eligibility starts from the first flight for all flights on the booking.
      </p>
    </div>
  )
}

import React, { useEffect, useState } from 'react';
import { setTitle } from '../utils/Title';
import { pingPageView } from '../utils/Analytics';
import { AddBookingForm } from '../components/AddBookingForm';
import { User, AllUsersResponse, UserSummary } from '../models/User';
import { fetchAllUsers } from '../utils/Fetch';
import CircularProgress from '@material-ui/core/CircularProgress';

export const AddBookingPage = (props : {user: User, isMaster: boolean}) => {
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [allUsersResponse, setAllUsersResponse] = useState<AllUsersResponse | null>(null);
  const [masterErr, setMasterErr] = useState("");

  const loadAllUsers = async () => {
    try {
      setIsRefreshing(true)
      const allUsersResult = await fetchAllUsers(true)
      setAllUsersResponse(allUsersResult)
    } catch (err) {
      setMasterErr((err as Error).message)
    } finally {
      setIsRefreshing(false)
    }
  }

  useEffect(() => {
    if (props.isMaster) {
      setTitle('Master Add Trip')
    } else {
      setTitle('Add Trip')
    }
    pingPageView()
    if (props.user.isAdmin) {
      loadAllUsers()
    }
  }, [props.user, props.isMaster])

  if (props.isMaster && !props.user.isAdmin) {
    return (
      <div>
        You're no master!
      </div>
    )
  }

  if (masterErr !== "") {
    return (
      <div>
        Error getting master: {masterErr}
      </div>
    )
  }

  if (isRefreshing) {
    return <div><CircularProgress /></div>
  }

  var allUsers: UserSummary[] = []
  if (allUsersResponse !== null) {
    allUsers = allUsersResponse.users
  }
  return (
    <div>
      <AddBookingForm isMaster={props.isMaster} allUsers={allUsers} />
    </div>
  )
}

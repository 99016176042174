import { useEffect, useState } from 'react';
import { useGoogleLogin, useGoogleLogout } from 'react-google-login';
import { User } from '../models/User';
import { setIdToken } from '../utils/User';
import { callLogIn } from '../utils/Fetch';

const clientId = '41027249231-pm7jrlqgv4gremd97t0s9mruh6plkui3.apps.googleusercontent.com'

export const useLogin = (onLogin: (user: User) => void) => {
  const [isLoginLoaded, setIsLoginLoaded] = useState(false)
  const {googleUser, isGoogleLoaded, logIn}  = useGoogLogin()

  useEffect(() => {
    if (!isGoogleLoaded) {
      setIsLoginLoaded(false)
    } else {
      if (!googleUser) {
        // Logged out.
        setIsLoginLoaded(true)
      } else {
        setIsLoginLoaded(false)
        // Logged in to Google. Now log in to Robo.
        const roboLogIn = async () => {
          try {
            const roboUser = await callLogIn()
            const user = {
              tokenId: googleUser.tokenId,
              name: googleUser.getBasicProfile().getName(),
              email: googleUser.getBasicProfile().getEmail(),
              imageUrl: googleUser.getBasicProfile().getImageUrl(),
              isAdmin: roboUser.is_admin,
            }
            onLogin(user)
          } catch (err) {
            window.alert(err)
          } finally {
            setIsLoginLoaded(true)
          }
        }
        roboLogIn()
      }
    }
  // Do not add onLogin to dependency array because it triggers very often. Not sure why.
  }, [isGoogleLoaded, googleUser, onLogin])

  return {logIn: logIn, isLoginLoaded: isLoginLoaded}
}

export const useLogout = (onLogout: () => void) => {
  const ret = useGoogleLogout({
    clientId: clientId,
    onLogoutSuccess: () => {
      setIdToken("")
      onLogout()
    },
    onFailure: () => {
      window.alert('Failed to sign out')
    },
  })
  return ret.signOut
}

export const useGoogLogin = () => {
  const [googleUser, setGoogleUser] = useState<any>(null)

  const {signIn, loaded} = useGoogleLogin({
    clientId: clientId,
    onSuccess: (resp: any) => {
      setIdToken(resp.tokenId)
      setGoogleUser(resp)
    },
    onFailure: (err: Error) => {
      window.alert(err)
    },
    isSignedIn: true
  })

  return {googleUser: googleUser, isGoogleLoaded: loaded, logIn: signIn}
}

//export const useGoogLogin = (onGoogleLogin: (googleUser: any) => void) => {
  //      onGoogleLogin({
   //     tokenId: resp.tokenId,
   //     name: resp.getBasicProfile().getName(),
   //     email: resp.getBasicProfile().getEmail(),
   //     imageUrl: resp.getBasicProfile().getImageUrl()
   //   })
import { getIdToken } from './User';
import { AddBookingRequest } from '../models/AddBooking';
import { SendMessageRequest } from '../models/SendMessage';

export const fetchFromApi = async (
  path: string,
  options: any,
  isAdmin: boolean,
): Promise<any> => {
  try {
    // Seems we need CORS even in production due to subdomain difference.
    options.mode = 'cors'
    options.headers = {
      'z-katch': '5h8p3541f73r-gh420po',
      'id-token': getIdToken()
    }

    // Optionally add the password header as another way to get admin access.
    var urlPath = `https://api.robofly.me/${path}`
    if (isAdmin) {
      urlPath += '?isAdmin'
    }

    const response = await fetch(urlPath, options)
    if (!response.ok) {
      throw Error(await response.text())
    }
    // If we get anything else back, server doesn't return JSON.
    if (response.status !== 200) {
      return await response.text()
    }
    return await response.json()
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const fetchBookings = (): Promise<any> => {
  return fetchFromApi('booking', {}, false)
}

export const addBooking = (
  code: string,
  firstName: string,
  lastName: string,
  phoneNumber: string,
  userId: string | null,
): Promise<any> => {
  const req: AddBookingRequest = {
    confirmationCode: code,
    firstName: firstName,
    lastName: lastName
  }
  if (phoneNumber !== "") {
    req.phoneNumber = phoneNumber
  }
  if (userId !== null) {
    req.userId = userId
  }
  const options = {
    method: 'POST',
    body: JSON.stringify(req),
  }
  return fetchFromApi('booking', options, false)
}

export const fetchAllBookings = (isAdmin: boolean): Promise<any> => {
  return fetchFromApi('booking', {}, isAdmin)
}

export const fetchAllUsers = (isAdmin: boolean): Promise<any> => {
  return fetchFromApi('user', {}, isAdmin)
}

export const checkIn = (code: string, isAdmin: boolean): Promise<any> => {
  return fetchFromApi(`check-in/${code}`, {}, isAdmin)
}

export const refreshBooking = (code: string, isAdmin: boolean): Promise<any> => {
  return fetchFromApi(`refresh-booking/${code}`, {}, isAdmin)
}



export const callLogIn = (): Promise<any> => {
  return fetchFromApi('login', {}, false)
}

export const sendMessage = (message: string): Promise<any> => {
  const req: SendMessageRequest = {
    message: message,
  }
  const options = {
    method: 'POST',
    body: JSON.stringify(req),
  }
  return fetchFromApi('contact', options, false)
}

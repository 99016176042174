import React, { useState } from 'react';
import { addBooking } from '../utils/Fetch';
import { Redirect } from 'react-router-dom';
import { useGlobal } from 'reactn';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import styled from 'styled-components';
import { Colors } from '../utils/Colors';
import { UserSummary } from '../models/User';
//import MuiPhoneNumber from 'material-ui-phone-number';

export const AddBookingForm = (props : {isMaster: boolean, allUsers: UserSummary[]}) => {
  const [, setIsAddSuccessful] = useGlobal('isAddSuccessful')
  const [success, setSuccess] = useState(false);
  const [code, setCode] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber] = useState("");
  const [userId, setUserId] = useState<string | null>(null);
  const [searchEnabled, setSearchEnabled] = useState(true);

  const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.value = event.target.value.toUpperCase()
    setCode(event.target.value)
  }

  const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(event.target.value)
  }

  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value)
  }

  const handleUserIdChange = (event: React.ChangeEvent<{}>, newValue: UserSummary | null) => {
    if (newValue == null) {
      setUserId(null)
    } else {
      setUserId(newValue.id)
    }
  }

  /*
  const handlePhoneNumberChange = (value: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string) => {
    if (typeof value == 'string') {
      setPhoneNumber(value)
    }
  }
  */

  const handleSubmit = (event: React.FormEvent<HTMLButtonElement>) => {
    const submitBooking = async () => {
      try {
        setSearchEnabled(false)
        await addBooking(code, firstName, lastName, phoneNumber, userId)
        setSuccess(true)
        setIsAddSuccessful(true)
      } catch (error) {
        setSearchEnabled(true)
        window.alert(error.message)
      }
    }

    submitBooking()
  }

  if (success) {
    return <Redirect to="/" />
  }

  var userSelector = <span></span>
  if (props.isMaster) {
    userSelector = (
      <Autocomplete
        options={props.allUsers}
        getOptionLabel={(userSummary) => `${userSummary.name} (${userSummary.email})`}
        onChange={handleUserIdChange}
        renderInput={(params) => <TextField
          {...params}
          margin="dense"
          required
          label="User"
          variant="outlined" />}
      />
    )
  }

  return (
    <TheContainer>
      <TheForm>
        <TextField
          autoFocus
          margin="dense"
          variant="outlined"
          required
          label="Confirmation Code"
          onChange={handleCodeChange}
        />
        <TextField
          margin="dense"
          variant="outlined"
          label="First name"
          required
          onChange={handleFirstNameChange}
        />
        <TextField
          margin="dense"
          variant="outlined"
          label="Last name"
          required
          onChange={handleLastNameChange}
        />
        {userSelector}
        {/*
        <MuiPhoneNumber
          margin="dense"
          variant="outlined"
          label="Phone number"
          defaultCountry='us'
          onlyCountries={['us']}
          disableCountryCode
          disableAreaCodes
          onChange={handlePhoneNumberChange}
          helperText="Add a phone number for text updates"
        >
        </MuiPhoneNumber>
        */}
        <HelperText>You'll receive confirmation via email that your booking was added.</HelperText>
        <Button
          variant="contained"
          color="primary"
          disabled={!searchEnabled}
          onClick={handleSubmit}
        >
          Add Trip
        </Button>
      </TheForm>
    </TheContainer>
  )
}

const TheForm = styled(FormGroup)`
  width: 100%;
`;

const TheContainer = styled(Container)`
  max-width: 300px;
  padding: 20px;
  background-color: ${Colors.lightGrey};
`;

const HelperText = styled.span`
  margin: 10px 0 10px 0;
  font-size: 12px;
`;
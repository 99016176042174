import React from 'react';
import styled from 'styled-components';
import { Colors } from '../utils/Colors';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import { User } from '../models/User';

export const Header = (props: {user: User, onClickSignOut: () => void}) => {
  const handleClickSignOut = () => {
    props.onClickSignOut()
  }

  var avatarUrl = props.user.imageUrl
  if (!avatarUrl) {
    avatarUrl = "/robot_avatar.png"
  }

  var avatar = (
    <div>
      <IconButton data-toggle="dropdown">
        <Avatar src={avatarUrl} alt={props.user.name} />
      </IconButton>
      <div className="dropdown-menu dropdown-menu-right">
        <a className="dropdown-item" href="/">Bookings</a>
        <a className="dropdown-item" href="/faq">FAQ</a>
        <a className="dropdown-item" href="/contact">Contact us</a>
        {props.user.isAdmin && <a className="dropdown-item" href="/master">Master View</a>}
        <div className="dropdown-divider" />
        <a className="dropdown-item" href="/" onClick={handleClickSignOut}>Log out</a>
      </div>
    </div>
  )

  return (
    <Wrapper>
      <Grid container justify="center" alignItems="center">
        <Grid item xs={3} sm={3} />
        <Grid item xs={6} sm={6}>
          <a href="/">
            <LogoImg src="/logo.svg" alt="Robofly" />
          </a>
        </Grid>
        <Grid item xs={3} sm={3}>
          <Grid container justify="flex-end">
            {avatar}
          </Grid>
        </Grid>
      </Grid>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  margin: 0px;
  height: 75px;
  background-color: ${Colors.niceBlue};
  z-index: 10;
`

const LogoImg = styled.img`
  display: block;
  height: 75px;
  margin: 0 auto;
`

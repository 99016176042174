import React from 'react'
import { FcGoogle } from 'react-icons/fc'
import { Colors } from '../utils/Colors'
import styled from 'styled-components';

interface LoginProps {
  onClick: () => any,
  isUnauthorized?: boolean
}

export const LoginPage = (props: LoginProps) => {
  var unauthorizedEl = <span />
  // Authorization not currently used.
  if (props.isUnauthorized) {
    unauthorizedEl = <p className="text-danger text-center"><strong>Who are you?</strong></p>
  }
  return (
    <div style={{height: '100vh', backgroundColor: Colors.niceBlue}}>
      <div style={{ display: 'flex', height: '70vh', width: '100%', justifyContent: 'center', alignItems:'center'}}>
        <div>
          <LogoContainer>
            <img src="/logo.svg" width="200px" alt="Robofly" />
          </LogoContainer>
          <span>{unauthorizedEl}</span>
          <button type="button" className="btn btn-light inter" onClick={props.onClick}>
            <FcGoogle style={{fontSize: '20px'}} />&nbsp;&nbsp;&nbsp;Sign in with Google
          </button>
        </div>
      </div>
    </div>
  )
}

const LogoContainer = styled.div`
  margin-bottom: 20px;
`;
import styled from 'styled-components';

export const BodyWrapper = styled.div`
  max-width: 100%;
  height: 100%;
  padding: 10px;
  margin-top: 90px;
  margin-left: 15px;
  margin-right: 15px;
  position: relative;
`;

import React from 'react';
import { Colors } from '../utils/Colors';
import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import { BookingResponse, Booking } from '../models/Booking';
import moment from 'moment';
import { AdminExtra } from './AdminExtra';

const timeFormat = "M/D/YY, h:mma"
const checkinTimeFormat = "M/D, h:mm:ssa"

export const BookingList = (props : {bookingResponse: BookingResponse, isMaster: boolean}) => {
  return (
    <div>
      <BookingStats bookingResponse={props.bookingResponse} />
      {props.bookingResponse.bookings.map((booking, bi) => (
        <BookingElement key={bi} booking={booking} isMaster={props.isMaster} />
      ))}
      <br/>
    </div>
  )
}

const BookingStats = (props: {bookingResponse: BookingResponse}) => {
  var numBookingsCompleted = 0;
  var numBookingsToGo = 0;
  var numCheckins = 0;
  for (var i: number = 0; i < props.bookingResponse.bookings.length; i++) {
    const booking = props.bookingResponse.bookings[i]
    if (booking.nextRunTime) {
      numBookingsToGo++;
    } else {
      numBookingsCompleted++;
    }
    numCheckins += booking.checkins.length
  }
  var numUsers = props.bookingResponse.numUsers

  return (
    <p>
      {numUsers != null && <Stat num={numUsers} text="user" />}
      <Stat num={numBookingsToGo} text="upcoming booking" />
      <Stat num={numCheckins} text="completed check-in" />
      <Stat num={numBookingsCompleted} text="past booking" />
    </p>
  )
}

const Stat = (props: {num: number, text: string}) => {
  if (props.num === 0) {
    return <span />
  } else {
    var text = props.text
    if (props.num > 1) {
      text += "s"
    }
    return (
      <span>
        <b>{props.num.toLocaleString()}</b> {text}<br/>
      </span>
    )
  }
}

export const BookingElement = (props: {booking: Booking, isMaster: boolean}) => {
  const booking = props.booking
  var nextRunTimeText = "Completed"
  if (booking.isCancelled) {
    nextRunTimeText = "Cancelled"
  }
  if (booking.nextRunTime) {
    nextRunTimeText = `Next check-in ${moment(booking.nextRunTime!).format(timeFormat)}`
  }
  var checkinsComponent = <i>No check-ins run<br/></i>
  if (booking.checkins.length > 0) {
    checkinsComponent = (
      <span>
        <i>Check-Ins</i><br/>
        {booking.checkins.map((checkin, ci) => (
          <span key={ci}>
            {checkin.detail} <DetailText>({moment(checkin.time).format(checkinTimeFormat)})</DetailText>
            <br/>
          </span>
        ))}
      </span>
    )
  }

  var phoneNumberComponent = <div />
  if (booking.phoneNumber) {
    phoneNumberComponent = <div><DetailText>{booking.phoneNumber}</DetailText><br/></div>
  }

  var internationalComponent = <span />
  if (booking.isInternational) {
    internationalComponent = (
      <DetailText>
        <i>International flight: Make sure your travel documents are in order on Southwest.com before your check-in time for Robofly to work properly.</i>
        <br/>
      </DetailText>
    )
  }

  const backgroundColor = booking.nextRunTime ? Colors.blueGrey : Colors.lightGrey
  const backgroundStyle = {'backgroundColor': backgroundColor} as React.CSSProperties
  return (
    <BookingBox elevation={5}>
      <BookingWrapper style={backgroundStyle}>
        <b>{booking.confirmationCode}</b><br/>
        <i>{nextRunTimeText}</i><br/>
        <br/>
        {booking.passengers.map((passenger, pi) => (
          <span key={pi}>{passenger.name}<br/></span>
        ))}
        <br/>
        {internationalComponent}
        {booking.trips.map((trip, ti) => (
          <span key={ti}>{trip.flightNumbers.join("/")} {trip.airports.join(" -> ")}: {trip.departureTime}<br/></span>
        ))}
        <br/>
        {checkinsComponent}
        <br/>
        {phoneNumberComponent}
        <DetailText>Added {moment(booking.addedTime).format(timeFormat)}</DetailText>
        <br/>
        {props.isMaster && (
          <AdminExtra booking={booking} isMaster={props.isMaster} />
        )}
      </BookingWrapper>
    </BookingBox>
  )
}

const BookingBox = styled(Paper)`
  width: 100%;
  margin-top: 10px;
`;

const BookingWrapper = styled.div`
  padding: 10px;
`;

const DetailText = styled.i`
  font-size: 12px;
`;

import * as React from 'react';
import { setTitle } from '../utils/Title';
import { pingPageView } from '../utils/Analytics';

export const Page404 = () => {
  setTitle('404')
  pingPageView()
  return (
    <div>
      <p>You lost?</p>
    </div>
  )
}

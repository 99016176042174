import React, { useState, useCallback } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { BodyWrapper } from './components/BodyWrapper';
import { Header } from './components/Header';
import { useLogin, useLogout } from './components/Login';
import { HomePage } from './pages/HomePage';
import { Page404 } from './pages/Page404';
import { AddBookingPage } from './pages/AddBookingPage';
import { ContactPage } from './pages/ContactPage';
import { FaqPage } from './pages/FaqPage';
import { MasterPage } from './pages/MasterPage';
import { LoginPage } from './pages/LoginPage';
import { setGlobal } from 'reactn';
import { User } from './models/User';

const App = () => {
  const [user, setUser] = useState<User | null>(null);

  setGlobal({
    isAddSuccess: false
  })

  const {logIn, isLoginLoaded}  = useLogin(
    useCallback((u) => {
      setUser(u)
    }, [])
  )

  const logOut = useLogout(() => {
    setUser(null)
  })

  if (!isLoginLoaded) {
    return <div />
  }

  if (!user) {
    return <LoginPage onClick={logIn} isUnauthorized={false} />
  }

  const content = (
    <Router>
      <Switch>
        <Route
          exact
          path="/add"
          render={(_) => (
            <AddBookingPage user={user} isMaster={false} />
          )}
        />
        <Route exact path="/index.html" component={HomePage} />
        <Route exact path="/faq" component={FaqPage} />
        <Route exact path="/contact" component={ContactPage} />
        <Route
          exact
          path="/master"
          render={(_) => (
            <MasterPage user={user} />
          )}
        />
        <Route
          exact
          path="/master/add"
          render={(_) => (
            <AddBookingPage user={user} isMaster={true} />
          )}
        />
        <Route exact path="/" component={HomePage} />
        <Route path="/" component={Page404} />
      </Switch>
    </Router>
  )

  return (
    <div>
      <Header user={user} onClickSignOut={logOut} />
      <BodyWrapper>
        {content}
      </BodyWrapper>
    </div>
  )
}

export default App;
